<template>
  <div id="kt_app_header" class="app-header">
    <div
      class="app-container container-xxl d-flex align-items-stretch justify-content-between"
      id="kt_app_header_container"
    >
      <div
        class="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15"
      >
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>

        <router-link to="/">
          <img
            alt="Logo"
            src="assets/media/logos/clev-logo.png"
            class="h-20px h-lg-30px app-sidebar-logo-default"
          />
        </router-link>
      </div>
      <div
        class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
        id="kt_app_header_wrapper"
      >
        <div
          class="app-header-menu app-header-mobile-drawer align-items-stretch"
          data-kt-drawer="true"
          data-kt-drawer-name="app-header-menu"
          data-kt-drawer-activate="{default: true, lg: false}"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="250px"
          data-kt-drawer-direction="end"
          data-kt-drawer-toggle="#kt_app_header_menu_toggle"
          data-kt-swapper="true"
          data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
          data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
        >
          <div
            class="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
            id="kt_app_header_menu"
            data-kt-menu="true"
          >
            <router-link
              to="/"
              class="menu-item me-0 me-lg-2"
              active-class="show" data-kt-drawer-dismiss="true"
            >
              <span class="menu-link"
                ><span class="menu-title">Dashboard</span
                ><span class="menu-arrow d-lg-none"></span
              ></span>
            </router-link>
            <router-link
              to="/jobs"
              class="menu-item me-0 me-lg-2"
              active-class="show" data-kt-drawer-dismiss="true"
            >
              <span class="menu-link"
                ><span class="menu-title">Task List</span
                ><span class="menu-arrow d-lg-none"></span
              ></span>
            </router-link>
            <router-link
              to="/schedule"
              class="menu-item me-0 me-lg-2" data-kt-drawer-dismiss="true"
              active-class="show"
            >
              <span class="menu-link"
                ><span class="menu-title">Planner</span
                ><span class="menu-arrow d-lg-none"></span
              ></span>
            </router-link>
            <router-link
              to="/clients"
              class="menu-item me-0 me-lg-2" data-kt-drawer-dismiss="true"
              active-class="show"
            >
              <span class="menu-link"
                ><span class="menu-title">Clients</span
                ><span class="menu-arrow d-lg-none"></span
              ></span>
            </router-link>
            <router-link
              to="/vans"
              class="menu-item me-0 me-lg-2" data-kt-drawer-dismiss="true"
              active-class="show"
              >
              <span class="menu-link"
              ><span class="menu-title">Vans</span
               ><span class="menu-arrow d-lg-none"></span>
               </span>
            </router-link>
            <router-link
              to="/users"
              class="menu-item me-0 me-lg-2"
              active-class="show" data-kt-drawer-dismiss="true"
            >
              <span class="menu-link"
                ><span class="menu-title">Users</span
                ><span class="menu-arrow d-lg-none"></span
              ></span>
            </router-link>
            <router-link 
              v-if="$auth.getRoleForUser() != 3" 
              to="reports" 
              class="menu-item me-0 me-lg-2"
              active-class="show"
              data-kt-drawer-dismiss="true"
            >
              <span class="menu-link">
                <span class="menu-title">Reports</span>
                <span class="menu-arrow d-lg-none"></span>
              </span>
            </router-link>
          </div>
        </div>

        <div class="app-navbar flex-shrink-0">
          <div class="app-navbar-item ms-1 ms-md-3">
            
            <router-link
              to="/settings"
              class="btn btn-icon btn-custom btn-icon-dark btn-active-primary btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
              title="Open settings"
            >
              <i class="fas fa-cog fs-2 fs-lg-1"></i>
            </router-link>
          </div>
          <div class="app-navbar-item ms-1 ms-md-3">
            
            <router-link
              to="/profile"
              class="btn btn-icon btn-custom btn-icon-dark btn-active-primary btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
              title="Open my profile"
            >
              <i class="fas fa-user fs-2 fs-lg-1"></i>
            </router-link>
          </div>
          <div class="app-navbar-item ms-1 ms-md-3">
            <a @click="$auth.signOut()"
            
            class="btn btn-icon btn-custom btn-icon-dark btn-active-primary btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
            title="Sign out">
              <i class="fas fa-sign-out fs-2 fs-lg-1"></i>
            </a>
          </div>
          <div class="app-navbar-item d-lg-none ms-2 me-n2" title="Show header menu">
              <div class="btn btn-icon btn-custom btn-icon-dark btn-active-primary btn-active-color-primary w-30px h-30px" id="kt_app_header_menu_toggle">
                 <i class="fas fa-bars fs-2 fs-lg-1"></i>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>